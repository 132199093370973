import {attr, belongsTo, hasMany} from "spraypaint";
import ApplicationRecord from "./baseModel";
import MarketplaceModel from './marketplaceModel';
import UserModel from "./userModel";
import OrderItemModel from "./orderItemModel";
import MarketplaceLocationsModel from "./marketplaceLocationsModel";

const OrderModel = ApplicationRecord.extend({
	static: { jsonapiType: "orders" },
	attrs: {
		id: attr(),
		fromCartId: attr(),
		deliveryType: attr(),
		publicUid: attr(),
		paymentType: attr(),
		comment: attr(),
		status: attr(),
		statusComment: attr(),
		itemsPrice: attr(),
		deliveryPrice: attr(),
		totalPrice: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),

		startProcessing: attr(),
		completeProcessing: attr(),
		giveToReceiver: attr(),
		startDelivery: attr(),
		completeDelivery: attr(),
		cancelByAdmin: attr(),
		cancelByMerchant: attr(),
		cancelByCourier: attr(),
		cancelByUser: attr(),

		userHasExistingCartWithItems: attr(),

		user: belongsTo(UserModel),
		marketplace: belongsTo(MarketplaceModel),
		courier: belongsTo(),
		pickupLocation: belongsTo(),
		orderItems: hasMany(OrderItemModel)
	}
})

export default OrderModel