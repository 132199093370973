import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Form, InputGroup, Badge, Alert} from "react-bootstrap";
import MerchantPageWrapper from "../../../components/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import DropZoneUploader from "../../../components/dropZoneUploader/dropZoneUploader";
import {attachHandler, uploadHandler} from "../../../helpers/directUpload";
import {getAllCategoriesForMarketplace} from "../../../api/categoryAPI";
import {getProduct} from "../../../api/productAPI";

const EditProductsPage = () => {
	const navigate = useNavigate();
	const { marketplaceId, productId } = useParams();
	const [photo, setPhoto] = useState('');
	const [currentProduct, setCurrentProduct] = useState({});
	const [isDeleteOpen, setDeleteOpen] = useState(false);
	const [categories, setCategories] = useState([]);
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		price: '',
		discountPrice: '',
		categoryId: '',
	});
	const [errors, setErrors] = useState({
		name: null,
		category: null,
		price: null
	});

	const getCategories = async () => {
		await getAllCategoriesForMarketplace(marketplaceId)
		.then(res => {
			setCategories(res);
		})
	};

	const getCurrentProduct = async () => {
		await getProduct(productId)
		.then(res => {
			setCurrentProduct(res);
			setFormData({
				name: (res?.unverifiedChanges?.name || res.name) || '',
				description: (res?.unverifiedChanges?.description || res.description) || '',
				price: res.price / 100 || '',
				discountPrice: res.discountPrice / 100 || '',
				categoryId: res.category.id || '',
			});
			setPhoto(`${window?.__CONFIG__?.REACT_APP_BASE_URL}${res.unverifiedImageUrl || res.imageUrl}`);
		})
	};

	useEffect(() => {
		getCategories();
		getCurrentProduct();
	}, []);

	const changeHandler = (name, value) => {
		setFormData(p => ({...p, [name]: value}));
	};

	const attachPhotoHandler = (file) => {
		attachHandler(file, (f, c) => setPhoto({ file: f, checkSum: c }));
	};

	const submitHandler = async () => {
		if (!formData.name || !formData.price || !formData.categoryId) {
			setErrors({
				name: !formData.name ? 'Необходимо заполнить название' : null,
				price: !formData.price ? 'Необходимо заполнить цену' : null,
				category: !formData.categoryId ? 'Необходимо выбрать категорию, если категории нет её нужно создать' : null,
			})
		} else {
			if (formData.name !== currentProduct.name || formData.description !== currentProduct.description) {
				currentProduct.unverifiedChanges = {
					name: formData.name,
					description: formData.description,
				};
			}
			currentProduct.price = formData.price * 100;
			currentProduct.discountPrice = formData.discountPrice === '' ? null : formData.discountPrice * 100;
			currentProduct.category = categories.find(category => category.id === formData.categoryId);

			if (photo?.file) {
				currentProduct.unverifiedImage = photo && (await uploadHandler(photo));
			}

			await currentProduct.save({ with: 'category.id'})
			.then(() => {
				navigate(`/merchant/${marketplaceId}/products`);
			})
		}
	};

	const statusBuilder = (status) => {
		switch (status) {
			case 'draft':
				return <Badge bg="danger">Черновик</Badge>;
			case 'verification':
				return <Badge bg="warning" text="dark">В процессе верификации</Badge>;
			case 'published':
				return <Badge bg="success">Товар опубликован</Badge>;
			default:
				return <Badge bg="secondary">Статус не определен</Badge>
		}
	};

	const moveToDrafthandler = async () => {
		currentProduct.unpublish = true;
		await currentProduct.save()
		.then(() => {
			getCurrentProduct();
		})
	};

	const moveToVerification = async () => {
		currentProduct.markForVerification = true;
		await currentProduct.save()
		.then(() => {
			getCurrentProduct();
		})
	}

	const deleteHandler = async () => {
		await currentProduct.destroy()
		.then(() => {
			navigate(`/merchant/${marketplaceId}/products`);
		})
	}

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				<Button
					variant="dark"
					onClick={() => {navigate(`/merchant/${marketplaceId}/products`)}}
					className="marginBottom20"
				>Назад</Button>

				<div className="flex-space-between">
					<h1 className="marginBottom20">Редактирование товара</h1>
					<div>
						Статус товара: {statusBuilder(currentProduct.status)}
					</div>
				</div>
				{isDeleteOpen
					? (
						<Alert
							variant="warning"
							style={{ width: '100%', margin: '0', display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}
						>
							{`Вы действительно хотите удалить товар?`}
							<div style={{ display: 'flex', columnGap: '12px'}}>
								<Button
									variant="success"
									onClick={() => deleteHandler()}
								>Удалить</Button>
								<Button
									variant="danger"
									onClick={() => setDeleteOpen(false)}
								>Отмена</Button>
							</div>
						</Alert>
					)
					: (
						<div className='flex'>
							{currentProduct.status !== 'draft' && (
								<Button
									variant="dark"
									onClick={() => moveToDrafthandler()}
									className="marginBottom20 marginRight20"
								>Отправить товар в черновики</Button>
							)}
							{currentProduct.status === 'draft' && (
								<Button
									variant="dark"
									onClick={() => moveToVerification()}
									className="marginBottom20 marginRight20"
								>Отправить на верификацию</Button>
							)}
							<Button
								variant="dark"
								onClick={() => setDeleteOpen(true)}
								className="marginBottom20 marginRight20"
							>Удалить товар</Button>
						</div>
					)
				}
				<InputGroup className="mb-3">
					<InputGroup.Text id="basic-addon1">Название (обязательное поле)</InputGroup.Text>
					<Form.Control
						placeholder="Название"
						aria-label="Название"
						aria-describedby="basic-addon1"
						value={formData.name}
						isInvalid={errors.name}
						onChange={e => changeHandler('name', e.target.value)}
					/>
					<Form.Control.Feedback type="invalid">
						{errors.name}
					</Form.Control.Feedback>
				</InputGroup>
				<InputGroup className="mb-3">
					<InputGroup.Text id="basic-addon2">Описание</InputGroup.Text>
					<Form.Control
						placeholder="Описание"
						aria-label="Описание"
						aria-describedby="basic-addon2"
						value={formData.description}
						onChange={e => changeHandler('description', e.target.value)}
					/>
				</InputGroup>
				<div className="grid2x">
					<InputGroup className="mb-3">
						<InputGroup.Text id="basic-addon2">Цена ₽ (Обязательное поле)</InputGroup.Text>
						<Form.Control
							placeholder="Цена"
							aria-label="Цена"
							aria-describedby="basic-addon2"
							value={formData.price}
							isInvalid={errors.price}
							onChange={e => changeHandler('price', e.target.value)}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.price}
						</Form.Control.Feedback>
					</InputGroup>
					<InputGroup className="mb-3">
						<InputGroup.Text id="basic-addon2">Цена со скидкой ₽</InputGroup.Text>
						<Form.Control
							placeholder="Цена со скидкой"
							aria-label="Цена со скидкой"
							aria-describedby="basic-addon2"
							value={formData.discountPrice}
							onChange={e => changeHandler('discountPrice', e.target.value)}
						/>
					</InputGroup>
				</div>
				<InputGroup className="mb-3 marginBottom20">
					<InputGroup.Text id="basic-addon2">Категория</InputGroup.Text>
					<Form.Select
						value={formData.categoryId}
						isInvalid={errors.category}
						onChange={(c) => changeHandler('categoryId', c.target.value)}
					>
						<option>Выберите категорию</option>
						{categories.map(c => (
							<option key={c.name} value={c.id}>{c.name}</option>
						))}
					</Form.Select>
					<Form.Control.Feedback type="invalid">
						{errors.category}
					</Form.Control.Feedback>
				</InputGroup>

				<DropZoneUploader
					preview={photo}
					acceptPreviewHandler={(f) => {
						if (f) attachPhotoHandler(f[0]);
					}}
					className="marginBottom20"
					text="Прикрепите фото товара в формате jpeg или png"
				/>
				<Button
					variant="dark"
					style={{display: 'block', width: '100%'}}
					onClick={() => submitHandler()}
					className="marginBottom20"
					// disabled={!formData.name || !formData.price || !formData.categoryId}
				>Сохранить</Button>
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default EditProductsPage