import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Spinner, Form, Badge, Modal, InputGroup} from "react-bootstrap";
import {getOrder} from "../../../api/orderAPI";
import MerchantPageWrapper from "../../../components/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import {
	OREDER_STATUSES,
	AWAITING_PROCCESING,
	PROCESSING,
	AWAITING_PICKUP,
	PICKEDUP_COURIER,
	DELIVERED
} from "../../../helpers/constants/statuses";
import {getLocationsForMarketplace} from "../../../api/marketplaceLocationsAPI";

const OrderPage = () => {
	const { marketplaceId, orderId } = useParams();
	const navigate = useNavigate();
	const [order, setOrder] = useState([]);
	const [reason, setReason] = useState('');
	const [isOpenCancel, setOpenCancel] = useState(false);
	const [locations, setLocations] = useState([]);
	const [pickupAddressId, setPickupAddressId] = useState('all');
	const [error, setError] = useState(false);

	const getCurrentOrder = async () => {
		await getOrder(orderId)
		.then(res => {
			setOrder(res);
			setPickupAddressId(res?.pickupLocation?.id || 'all');
		})
	}
	const getLocations = async () => {
		await getLocationsForMarketplace(marketplaceId)
		.then(res => {
			setLocations(res);
		})
	};

	console.log(order);

	useEffect(() => {
		getCurrentOrder();
		getLocations();
	}, []);

	const startProcessing = async () => {
		order.startProcessing = true;
		await order.save();
		getCurrentOrder();
	}

	const completeProcessing = async () => {
		if (order.pickupLocation) {
			order.completeProcessing = true;
			await order.save();
			getCurrentOrder();
		} else {
			setError(true)
		}
	}

	const giveToReceiver = async () => {
		order.giveToReceiver = true;
		await order.save();
		getCurrentOrder();
	}

	const closeHandler = () => {
		setOpenCancel(false);
		setReason('');
	}

	const cancelOrderHandler = async () => {
		order.cancelByMerchant = true;
		order.statusComment = reason;

		await order.save()
		.then(() => {
			getCurrentOrder();
		})
	}

	const setAddressToOrder = async () => {
		order.pickupLocation = locations.find(l => l.id === pickupAddressId);
		await order.save({ with: 'pickupLocation.id'})
		.then(() => {
			getCurrentOrder();
		})
	};

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				{order
					? (
						<>
							<Modal show={isOpenCancel} onHide={() => closeHandler()}>
								<Modal.Header closeButton>
									<Modal.Title>Отмена заказа</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
										<Form.Label>Причина отмены</Form.Label>
										<Form.Control as="textarea" value={reason} onChange={e => setReason(e.target.value)} rows={3} />
									</Form.Group>
								</Modal.Body>
								<Modal.Footer>
									<Button variant="secondary" onClick={() => closeHandler()}>
										Закрыть
									</Button>
									<Button variant="danger" disabled={!reason} onClick={() => cancelOrderHandler()}>
										Отменить заказ
									</Button>
								</Modal.Footer>
							</Modal>

							<Button
								variant="dark"
								onClick={() => {navigate(`/merchant/${marketplaceId}/orders`)}}
								className="marginBottom20"
							>Назад</Button>
							<h1 className="marginBottom20">Заказ: {order.publicUid}</h1>
							<div className="flex-space-between marginBottom20">
								<div>
									Текущий статус заказа:
									<Badge bg={(order.status === DELIVERED || order.status === PICKEDUP_COURIER) ? "success" : "warning"}>
										{OREDER_STATUSES[order.status]?.translate || ''}
									</Badge>
								</div>
								<div className="flex">
									{order.status === AWAITING_PROCCESING && (
										<Button
											variant="dark"
											onClick={() => startProcessing()}
										>Начать сборку заказа</Button>
									)}
									{order.status === PROCESSING && (
										<Button
											variant="dark"
											onClick={() => completeProcessing()}
										>Заказ собран</Button>
									)}
									{order.status === AWAITING_PICKUP && (
										<Button
											variant="dark"
											onClick={() => giveToReceiver()}
										>Заказ выдан</Button>
									)}
									{(order.status === AWAITING_PROCCESING || order.status === PROCESSING || order.status === AWAITING_PICKUP) && (
										<Button
											variant="dark"
											className="marginLeft20"
											onClick={() => setOpenCancel(true)}
										>Отменить заказ</Button>
									)}
								</div>
							</div>
							{
								((order.status === AWAITING_PROCCESING || order.status === PROCESSING)) && (
									<div className="block marginBottom20">
										<b className="flex marginBottom20">Выберите адрес откуда курьер заберет заказ</b>
										<div className="flex">
											<InputGroup className="marginRight20">
												<InputGroup.Text>Адрес выдачи заказа</InputGroup.Text>
												<Form.Select
													aria-label="Выберите роль"
													aria-placeholder="Выберите роль"
													onChange={e => setPickupAddressId(e.target.value)}
													value={pickupAddressId}
													isInvalid={!!error}
												>
													<option value={'all'} disabled>Выберите категорию</option>
													{locations.map(tag => (
														<option value={tag.id} key={tag.id}>{`${tag.name} - ${tag.address}`}</option>
													))}
												</Form.Select>
												<Form.Control.Feedback type="invalid">
													Необходимо указать адрес, откуда курьер заберёт заказ
												</Form.Control.Feedback>
											</InputGroup>
											<Button
												style={{width: '300px'}}
												variant="dark"
												onClick={() => setAddressToOrder()}
												disabled={pickupAddressId === 'all'}
											>
												Выбрать адрес
											</Button>
										</div>
									</div>
								)
							}
							{(order.pickupLocation) && (
								<>
									<h3>Заказ отдадут курьеру по адресу:</h3>
									<div className="marginBottom40">
										{order.pickupLocation.address}
									</div>
								</>
							)}
							{(order.orderItems && order.orderItems.length > 0) && (
								<>
									<h3>Состав заказа:</h3>
									<div className="marginBottom40">
										{order.orderItems.map((item, idx) => (
											<div>{`${idx + 1}) ${item.product.name || 'Имя не найдено'} - Количество ${item.quantity}шт.`}</div>
										))}
									</div>
								</>
							)}
							{order.comment && (
								<>
									<h3>Комментарий к заказу:</h3>
									<div className="marginBottom40">
										{order.comment}
									</div>
								</>
							)}
							{order.statusComment && (
								<>
									<h3>Комментарий к отмене заказа:</h3>
									<div className="marginBottom40">
										{order.statusComment}
									</div>
								</>
							)}
						</>
					)
					 : (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default OrderPage